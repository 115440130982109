html {
  background: url('assets/pattern.jpg');
  background-color: rgb(19,17,36);
  background-size: 750px 750px;
  scroll-behavior: smooth;
}

body {
  font-family: Lato,sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 3em 1em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.4;
  color: #eee;
 }

#root {
  background-color: rgba(10, 17, 36, 0.8);
  padding: 40px;
  border-radius: 10px;
  border: 1px solid black;
}
@media (max-width: 500px) {
  body {
    padding: 0;
    min-height: 100%;
  }
  #root {
    border-radius: 0;
    min-height: 100vh;
    background-color: rgba(10, 17, 36, 0.8);
  }
}

.description {
  display: flex;
  justify-content: center;
}

.description ul {
  text-align: left;
}
.description > div h4 {
  text-align: center;
}

.extras {
  max-width: 400px;
  margin: auto;
}
.extras > b {
  display: block;
  padding-bottom: 5px;
}
.extras > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
}

.user {
  font-size: 22px;
  font-weight: bold;
}
.loginInfo {
  padding-bottom: 25px;
}
button {
  font-size: 1rem;
}

div {
  padding: 10px 0;
}

div.spinner {
  border: 10px solid rgba(0,0,0,0.4);
  border-top: 10px solid rgba(255,255,255, 0.4); /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
  margin: 50px auto;
  padding: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.images {
  width: 75vw;
  max-width: 650px;
}
.keyboard-image {
  display: none;
  margin: auto;
  border: 1px solid #333;
  border-radius: 10px;
}
.keyboard-image.active {
  display: block;
  width: 75vw;
  max-width: 650px;
}

button, [type="button"], [type="submit"], [type="reset"], a.button, label.button, .button, a[role="button"], label[role="button"], [role="button"] {
  display: inline-block;
  background: #000;
  color: #eee;
  border: 1px solid #333;
  border-radius: 0.5rem;
  padding: .8rem calc(1.5 * .8rem);
  margin: .5rem;
  text-decoration: none;
  cursor: pointer;
}

button:hover, button:focus, [type="button"]:hover, [type="button"]:focus, [type="submit"]:hover, [type="submit"]:focus, [type="reset"]:hover, [type="reset"]:focus, a.button:hover, a.button:focus, label.button:hover, label.button:focus, .button:hover, .button:focus, a[role="button"]:hover, a[role="button"]:focus, label[role="button"]:hover, label[role="button"]:focus, [role="button"]:hover, [role="button"]:focus {
  background: #222;
  border-color: #000;
  outline: none;
}
button.active {
  font-weight: bold;
  border: 2px solid #fff;
  padding-left: 25px;
  padding-right: 25px;
}

a, a:visited, a:focus {
  color:rgb(45, 140, 169);
  word-wrap:break-word;
}
a:hover {
  color:rgb(24, 120, 149);
}

input:not([type]), [type="text"], [type="email"], [type="number"], [type="search"], [type="password"], [type="url"], [type="tel"], [type="checkbox"], [type="radio"], textarea, select {
  box-sizing: border-box;
  background: rgba(0,0,0,0.4);
  color:  #eee;
  border: .0625rem solid #333;
  border-radius: 0.5rem;
  margin: .25rem;
  padding: .5rem;
  font-size: 16px;
}
.raffle-options input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.raffle-options label {
  display: inline-block;
  width: 8em;
  background-color: rgba(0,0,0,0.4);
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #333;
  border-radius: 4px;
}
.raffle-options label:hover {
  display: inline-block;
  background-color: rgba(51,51,51,0.4);
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #333;
  border-radius: 4px;
}
.raffle-options input[type="radio"]:checked + label {
  border: 2px solid #fff;
  background-color: rgb(51,51,51,0.75);
}
.warning {
  color: red;
  font-weight: bold;
}
.modal {
  position: fixed;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
}
.modal > div {
  width: calc(100vw - 140px);
  max-width: 400px;
  margin: 20px auto;
  padding: 50px 40px;
  border-radius: 10px;
  background-color: rgba(10,17,36, 0.8);
  border: 1px solid #333;
}
@media screen and (max-width: 500px) {
  .modal > div {
    padding: 40px 20px;  
    width: calc(100vw - 100px);
  }
}
h2 {
  margin-block-start: 0.3em;
}
h3, h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
p {
  max-width: 650px;
}